import React, {useEffect} from 'react'
import db from './firebase'
import {useParams} from 'react-router-dom'

function Redirect() {
    const {slug} = useParams()
    useEffect(() => {
        let query = db.collection('urls').where('slug', '==', slug)
        query.onSnapshot((data)=>{
            if(data.empty){
                alert('URL not found')
            }
            let finalData = data.docs[0].data()
            let url = finalData.url
            window.location.href = `vnd.youtube://youtube.com/redirect?event=comments&redir_token=QUFFLUhqbXJ0NkdqdW1rWGlsM3RqQTNrWS1SZlR0X0x5UXxBQ3Jtc0trTlpVbVpaOWhyejByVm5CdTBKVnkyWnhrOU9TTVc5dU81VGc1Z3FTOVBzU09tVDYtanIxV3JMQ29FQ3BOMUphUzdjYjVKZW5xUmxvc01WS2NmUlRFdFU4RE42V3hTd2QwU1JBYWN0MHJocTBmUGc0WQ&q=${url}&html_redirect=1`
        })
    }, [slug])
    return (
        <div>
            <center><p>connecting</p></center>
        </div>
    )
}

export default Redirect
